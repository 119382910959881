.customEditor .toastui-editor-defaultUI,
.customEditor .ProseMirror,
.customEditor .toastui-editor-contents {
  font-family: Nunito;
  font-style: normal;
  font-family: "Nunito";
  font-size: 16px;
  color: #282c34;
}

.customEditor h1,
.customEditor .article-h1 {
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.5rem;
}

.customEditor h2,
.customEditor .article-h2 {
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.5rem;
}

.customEditor h3,
.customEditor .article-h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.customEditor h4,
.customEditor .article-h4 {
  font-size: 1.375rem;
  line-height: 1.5rem;
}

.customEditor h5,
.customEditor .article-h5 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.customEditor h6,
.customEditor .article-h6 {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.customEditor p,
.customEditor span,
.customEditor .article-p,
.customEditor .article-span {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}
.customEditor .toastui-editor-contents {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 48px;
}
.customEditor iframe {
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto;
  min-height: 300px;
  overflow: hidden;
}
